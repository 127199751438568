/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImageS3 } from './ImageS3';
import {
    ImageS3FromJSON,
    ImageS3FromJSONTyped,
    ImageS3ToJSON,
} from './ImageS3';
import type { OrderRelationType } from './OrderRelationType';
import {
    OrderRelationTypeFromJSON,
    OrderRelationTypeFromJSONTyped,
    OrderRelationTypeToJSON,
} from './OrderRelationType';

/**
 * Сериализация объекта товара корзины
 * @export
 * @interface OrderItem
 */
export interface OrderItem {
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    readonly title: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    readonly productId: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    readonly productIntegrationId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    readonly productTrademark: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    readonly code: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItem
     */
    promocodeBlocked: boolean;
    /**
     * 
     * @type {Array<OrderRelationType>}
     * @memberof OrderItem
     */
    readonly productType: Array<OrderRelationType>;
    /**
     * 
     * @type {Array<OrderRelationType>}
     * @memberof OrderItem
     */
    readonly petType: Array<OrderRelationType>;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    bonusOff?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    readonly stockBalances: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    readonly coverPic: string;
    /**
     * 
     * @type {Array<ImageS3>}
     * @memberof OrderItem
     */
    readonly imageS3: Array<ImageS3>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderItem
     */
    readonly characteristics: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    totalPrice: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    totalUserPrice: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    readonly discountValue: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    manualDiscount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    readonly automaticDiscount: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItem
     */
    isPromoOneRub: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    price?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    userPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    manualDiscountFix?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItem
     */
    isPriceChanged?: boolean;
}

/**
 * Check if a given object implements the OrderItem interface.
 */
export function instanceOfOrderItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "productIntegrationId" in value;
    isInstance = isInstance && "productTrademark" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "promocodeBlocked" in value;
    isInstance = isInstance && "productType" in value;
    isInstance = isInstance && "petType" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "stockBalances" in value;
    isInstance = isInstance && "coverPic" in value;
    isInstance = isInstance && "imageS3" in value;
    isInstance = isInstance && "characteristics" in value;
    isInstance = isInstance && "totalPrice" in value;
    isInstance = isInstance && "totalUserPrice" in value;
    isInstance = isInstance && "discountValue" in value;
    isInstance = isInstance && "manualDiscount" in value;
    isInstance = isInstance && "automaticDiscount" in value;
    isInstance = isInstance && "isPromoOneRub" in value;

    return isInstance;
}

export function OrderItemFromJSON(json: any): OrderItem {
    return OrderItemFromJSONTyped(json, false);
}

export function OrderItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'productId': json['product_id'],
        'productIntegrationId': json['product_integration_id'],
        'productTrademark': json['product_trademark'],
        'code': json['code'],
        'promocodeBlocked': json['promocode_blocked'],
        'productType': ((json['product_type'] as Array<any>).map(OrderRelationTypeFromJSON)),
        'petType': ((json['pet_type'] as Array<any>).map(OrderRelationTypeFromJSON)),
        'quantity': json['quantity'],
        'bonusOff': !exists(json, 'bonus_off') ? undefined : json['bonus_off'],
        'stockBalances': json['stock_balances'],
        'coverPic': json['cover_pic'],
        'imageS3': ((json['image_s3'] as Array<any>).map(ImageS3FromJSON)),
        'characteristics': json['characteristics'],
        'totalPrice': json['total_price'],
        'totalUserPrice': json['total_user_price'],
        'discountValue': json['discount_value'],
        'manualDiscount': json['manual_discount'],
        'automaticDiscount': json['automatic_discount'],
        'isPromoOneRub': json['is_promo_one_rub'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'userPrice': !exists(json, 'user_price') ? undefined : json['user_price'],
        'manualDiscountFix': !exists(json, 'manual_discount_fix') ? undefined : json['manual_discount_fix'],
        'isPriceChanged': !exists(json, 'is_price_changed') ? undefined : json['is_price_changed'],
    };
}

export function OrderItemToJSON(value?: OrderItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'promocode_blocked': value.promocodeBlocked,
        'quantity': value.quantity,
        'bonus_off': value.bonusOff,
        'total_price': value.totalPrice,
        'total_user_price': value.totalUserPrice,
        'manual_discount': value.manualDiscount,
        'is_promo_one_rub': value.isPromoOneRub,
        'price': value.price,
        'user_price': value.userPrice,
        'manual_discount_fix': value.manualDiscountFix,
        'is_price_changed': value.isPriceChanged,
    };
}

