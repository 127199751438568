<template>
  <div
    v-if="showAlert"
    class="shadow-box fixed bottom-20 left-2 z-[100] flex w-[95%] max-w-[400px] items-center justify-between rounded-2xl p-3.5 text-xs sm:text-sm md:translate-x-1/2 md:gap-7 lg:bottom-12 lg:left-12 lg:translate-x-0"
    style="background: rgba(0, 0, 0, 0.8)"
  >
    <div class="flex gap-2 text-white">
      <img
        src="@valta/assets/images/cookie.png"
        width="18"
        height="18"
        alt=""
        class="object-contain object-top"
      />
      <div class="font-gilroy font-medium">
        Мы используем cookie&nbsp;-&nbsp;файлы
        <Btn to="/rules/cookie/" class="block underline hover:opacity-90">
          Узнать больше
        </Btn>
      </div>
    </div>
    <Btn
      class="h-8 w-[102px] rounded-full bg-white text-[#0F0F0F] hover:opacity-90"
      @click="acceptCookies"
    >
      Хорошо
    </Btn>
  </div>
</template>

<script setup lang="ts">
const showAlert = ref(false)

const sessionDuration = 2592000000

function acceptCookies() {
  showAlert.value = false
  const now = new Date().getTime()
  localStorage.setItem(
    'cookieAccepted',
    JSON.stringify({ accepted: true, timestamp: now }),
  )
}

function isSessionValid() {
  const data = localStorage.getItem('cookieAccepted')
  if (data) {
    const { accepted, timestamp } = JSON.parse(data)
    const now = new Date().getTime()
    return accepted && now - timestamp < sessionDuration
  }
  return false
}

onMounted(() => {
  showAlert.value = !isSessionValid()
})
</script>

<style>
.shadow-box {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}
.__jivoMobileButton {
  bottom: 130px !important;
}
</style>
