<template>
  <div
    class="fixed bottom-0 z-30 w-full bg-white shadow-[0px_0px_44px_0px_rgb(0_0_0/0.1)] md:hidden"
    :class="{ 'h-[66px]': !showAction }"
  >
    <slot name="action_cart" />

    <div
      class="mx-auto flex h-full max-w-[345px] items-center justify-around py-2"
    >
      <Btn
        v-for="item in tabItems"
        :key="item.path"
        :to="item.auth ? undefined : item.path"
        secondary
        :class="[
          'w-[42px] p-0',
          route.name === item.path ? 'text-icon-accent' : 'text-icon-secondary',
        ]"
        @click.prevent="goToAuthPage(item.path, item.auth)"
      >
        <div class="flex flex-col items-center">
          <Icon :name="item.icon" class="h-6 w-6" />
          <div v-if="!hideTitle" class="text-[10px]">
            {{ item.title }}
          </div>
        </div>

        <div
          v-if="item.count"
          class="bg-primary absolute -right-2 -top-2 flex h-4 min-w-[1rem] items-center justify-center rounded-full px-1 text-white shadow-[0px_4px_4px_0px_rgb(0_0_0/0.1)]"
        >
          <span class="text-xs leading-none">{{ item.count }}</span>
        </div>
      </Btn>
    </div>
  </div>
</template>

<script setup lang="ts">
type TabItems = {
  path: string
  icon: IconName
  title: string
  auth?: boolean
  count?: number
}

const props = defineProps<{
  hideCompare?: boolean
  hideCart?: boolean
  hideTitle?: boolean
  showAction?: boolean
  homeLogo?: string
}>()

const { loggedIn, login } = useAuth()
const profileStore = useProfileStore()
const cartStore = useCartStore()
const route = useRoute()

const tabItems = computed(
  () =>
    [
      {
        path: '/',
        icon: props.homeLogo || 'home',
        title: 'Главная',
      },
      {
        path: '/catalog/',
        icon: 'catalog',
        title: 'Каталог',
      },
      !props.hideCompare && {
        path: '/compare/',
        icon: 'compare',
        title: 'Сравнение',
        count: profileStore.compareIds.length,
      },
      {
        path: '/favorites/',
        icon: 'favorite-rounded',
        title: 'Избранное',
        count: profileStore.favoriteIds.length,
      },
      {
        auth: true,
        path: '/profile/',
        icon: 'user-1',
        title: loggedIn.value ? 'Профиль' : 'Авторизоваться',
      },
      !props.hideCart && {
        path: '/cart/',
        icon: 'cart',
        title: 'Корзина',
        count: cartStore.cart?.totalProductCount,
      },
    ].filter(Boolean) as TabItems[],
)

async function goToAuthPage(path: string, auth?: boolean) {
  if (auth && !loggedIn.value) {
    await login(route.fullPath)
  } else {
    await navigateTo(path)
  }
}
</script>
