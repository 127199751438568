export default async function () {
  const route = useRoute()
  const { loggedIn } = useAuth()
  const url = useRequestURL()

  useHead({
    link: [{ rel: 'canonical', href: () => `${url.origin}${route.path}` }],
  })

  const { t } = useI18n()
  useSchemaOrg([
    defineImage({
      name: `Логотип ${t('pages.contacts.organization')}`,
      url: '/logo.png',
    }),
  ])

  const profileStore = useProfileStore()

  // подгружаем корзину при старте приложения
  const cartStore = useCartStore()

  await useAsyncData('get-session-id', () =>
    useOpenApi().AccountApi.accountsSessionRetrieve(),
  )

  if (process.server) return

  if (route.name !== 'cart') cartStore.load()

  const promoCodeQuery = String(route.query?.promocode ?? '')
  if (promoCodeQuery) {
    profileStore.promoCode = promoCodeQuery
  }
  watch(
    // зарегистриуем промокод, когда пользователь будет авторизаван. в localStorage промокод будет заполнен, корзина создана, в корзине не будет активизированного промокода
    () =>
      loggedIn.value &&
      profileStore.promoCode &&
      cartStore.cart !== null &&
      !cartStore.cart.promoCode,
    async (val, old) => {
      const promoCode = profileStore.promoCode
      if (!val || val === old) return
      await useAsyncData('promocode-create', () =>
        useOpenApi().LoyalityApi.loyalityPromoCodeCreate({
          promoCodeCreateRequest: { promoCode },
        }),
      )
    },
  )
}
