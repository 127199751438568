const linkList = [
  {
    text: 'Профиль',
    to: '/profile/',
    mobileOnly: true,
    auth: true,
    dataTest: 'profile',
  },
  {
    text: 'О компании',
    to: '/about/',
    dataTest: 'about',
  },
  {
    text: 'Каталог',
    to: '/catalog/',
    mobileOnly: true,
    dataTest: 'catalog',
  },
  {
    text: 'Где купить',
    to: '/map/',
    dataTest: 'where_to_buy',
  },
  {
    text: 'Контакты',
    to: '/contacts/',
    dataTest: 'contacts',
  },
  {
    text: 'Сотрудничество',
    to: '/cooperation/',
    dataTest: 'cooperation',
  },
  {
    text: 'Заводчикам',
    to: '/breeder/',
    dataTest: 'breeder',
  },
  {
    text: 'Новости',
    to: '/articles/',
    dataTest: 'articles',
  },
  {
    text: 'Вет. диеты',
    to: 'https://vetsolution.ru',
    external: true,
    class: '!text-[#005B98] hover:!text-[#337CAD]',
    dataTest: 'vetsolution',
  },
]
export const header = linkList.map((item) => ({
  ...item,
  dataTest: 'header_' + item.dataTest,
}))
export const footer = [
  {
    title: 'О компании',
    children: linkList
      .filter((i) => !i.mobileOnly)
      .map((i) => ({
        title: i.text,
        href: i.to,
        dataTest: 'footer_' + i.dataTest,
      })),
  },
  {
    title: 'Покупателям',
    children: [
      {
        title: 'Условия продажи',
        href: '/terms_of_sales.pdf',
        type: 'file',
        dataTest: 'footer_terms_of_sales',
      },
      {
        title: 'Условия возврата',
        href: 'https://brands4pets.ru/refund.pdf',
        type: 'file',
        dataTest: 'footer_refund',
      },
      {
        title: 'Доставка и оплата',
        href: '/payment_and_delivery.pdf',
        type: 'file',
        dataTest: 'footer_payment_and_delivery',
      },
    ],
  },
  {
    title: 'Линии',
    children: [
      {
        title: 'Gift',
        href: '/gift/',
        dataTest: 'footer_gift',
      },
      {
        title: 'BWild',
        href: '/bwild/',
        dataTest: 'footer_bwild',
      },
      {
        title: 'Speciality Line',
        href: '/catalog/speciality/',
        dataTest: 'footer_speciality',
      },
      {
        title: 'Daily Line',
        href: '/catalog/daily/',
        dataTest: 'footer_daily',
      },
      {
        title: 'Supreme',
        href: '/catalog/supreme/',
        dataTest: 'footer_supreme',
      },
    ],
  },
]

export const social = [
  {
    href: 'https://vk.com/monge_korm',
    icon: 'vk',
    dataTest: 'vk',
  },
  {
    href: 'https://ok.ru/monge',
    icon: 'od',
    dataTest: 'ok',
  },
]

export const contacts = {
  phoneTo: '8 (800) 600-28-04',
}
